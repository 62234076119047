.container{
    width: 600px;
    margin: 50px auto;
    background-color: #ff004072;
    border-radius: 5%;
    padding-bottom: 25px;
}


.form{
    display: flex;
    flex-direction: column;
    margin: auto;
    
}

.name, .email {
    margin: 25px auto;
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 80%;
    height: 40px;
    border: none;
    text-align: left;
}

.name {
    margin-top: 50px;
}

.message {
    margin: 25px auto;
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 80%;
    height: 200px;
    border: none;
    text-align: left;
    
}




.send{
    width: 80%;
    margin: 20px auto;
    height: 40px;
    border: none;
    background: #434753;
    color: white;
    cursor: pointer;
}

input, textarea {
    padding: 10px;
}

@media only screen and (max-width: 480px) {
    .container {
        width: 350px;
    }

    .form {
        width: 260px;
    }

    .name, .email, .message {
        width: 100%;
        
    }

    .name {
        margin-top: 50px;
    }
}