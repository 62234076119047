.about-subtitle {
    text-align: center;
    margin: 50px;
    font-size: 50px;
    color: #FF0077;
}

.about-info {
    text-align: center;
    margin-top: 50px;
    font-size: 25px;
    font-weight: 600;
}


.about-btn {
    background-color: white;
    border: 2px solid #FF0077;
    margin: 20px;
    width: 250px;
    height: 100px;
    font-size: 25px;
    font-weight: 600;
}

.about-btn:hover {
    background-color: #FF0077;
    color: white;
}

.about-btn:active {
    background-color: white;
}


.btn-div {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.btn-text {
    margin-top: 40px;
}

.list-content {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  margin: auto;
  list-style: none;
  text-align: center;
  width: 400px;
}

li {
    margin-right: 60px;
    margin-top: 5px;
}

.skills-grid {
    display: grid;
    width: 800px;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    column-gap: 50px;
   
}

.grid-item {
    min-width: 0px; 
    font-weight: 600;
    padding-bottom: 40px; 
    border: 3px solid #FF0077;  
    
}

.list-title {
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    margin: 15px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 20px;
    font-weight: bold;
    color: #FF0077;
}

.skill-icon {
    width: 60px;
    height: 60px;
    border: 3px solid #FF0077;
}

.skill-title {
   margin-left: 20px;
   margin-top: 13px;
   
}

.grid-item:hover  {
    background-color: #ff007759;
    color: white; 
    
}

.whitespace {
    height: 40vh;
    
}

@media only screen and (max-width: 480px) {

.about-subtitle {
    font-size: 30px;
  }
 .about-info {
    font-size: 18px;
    margin: 20px; 
 }

 .about-btn {
    width: 150px;
    height: 75px;
    font-size: 18px;
 }

 .about-btn:after {
    width: 150px;
    height: 75px;
    font-size: 18px;
 }

 .list-content {
    font-size: 14px;
    width: 400px;
    padding: 0px;
    margin-left: 20px;
  }
  
  li {
      margin-top: 1px;
      width: 175px;
  }
  
  .skills-grid {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      margin: auto;
      column-gap: 10px;
  }
  .grid-item {
      width: 100%; 
      font-weight: 600;
      padding-bottom: 40px; 
      border: 3px solid #FF0077;  
  }
  .list-title { 
      font-size: 18px;
      padding-top: 0px;
  }
  .skill-icon {
      width: 60px;
      height: 60px;
      border: 3px solid #FF0077;
  }
  .skill-title {
     margin-left: 5px;
     margin-top: 13px;
  }
  .grid-item:hover  {
      background-color: #ff007759;
      color: white; 
      
  }
}

a {
    color:#FF0077 !important;
    text-decoration: none !important;
}

a:hover {
    text-decoration: underline !important;
}