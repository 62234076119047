@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


.content {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  text-align: center;
  width: 70vw;
  margin: auto;
}

.logo {
  width: 52px;
  height: 50px;
  display: inline;
  margin-left: 30px;
  margin-right: 20px;
}

.page-heading {
  font-family: 'Poppins', sans-serif;
  color: white;
  font-size: 50px;
  background-color: rgba(201, 200, 200, 0);
  border: 5px solid #FF0077;
  text-align: center;
  margin-top: 50px;
  padding: 10px 30px;

}

#head {
  background: url(../assets/ProfilePicture.JPG) top center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, .85);
  background-size: cover;
  padding: 30px 0 35px 0;
  height: 85vh;
  width: 100%;
  text-align: center;
  line-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#head .title {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  color: white;
  font-size: 6vw;
  line-height: 1;
  letter-spacing: -.1rem;
  margin-top: 0;
}

#head .subtitle {
  font-family: 'Poppins', sans-serif;
  color: white;
  word-spacing: 1.8vw;
  font-size: 25px;
}

#helloworld {
  font-size: 3vw;
  font-family: 'Poppins', sans-serif;
  color: #FF0077;
  margin-bottom: 0;
  font-weight: bolder;
  letter-spacing: 3px;
}

#head .button {
  background-color: rgba(201, 200, 200, 0);
  color: white;
  border: 1px solid white;
  text-align: center;
  margin-top: 50px;
  padding: 0px 20px;
  font-family: 'Poppins', sans-serif;
}

#head .button:hover {
  border-color: #FF0077;
}

#head .button:active {
  border-color: white;
}

.home-subtitle {
  text-align: center;
  margin: 50px;
  font-size: 50px;
}

.link:active {
  color: black;
}

.link:hover {
  color: #FF0077;
}

.link {
  color: rgba(0, 0, 0, 0.749);
  text-decoration: none;
  font-weight: bold;
}

.navigation {
  font-size: 20px;
  position: fixed;
  width: 100%;
  text-align: center;
  background: white;
  z-index: 99;

}

.icons {
  width: 110px;
  height: 100px;
  display: inline;
}

.grid-container {
  margin-top: 70px;
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.footer {
  width: 100%;
  color: black;
  padding-top: 70px;
  padding-bottom: 30px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  text-align: center;
}

.content-img {
  position: relative;
  float: left;
  margin-right: 10px;

}

.content-img div {
  width: 110px;
  height: 100px;
  line-height: 100px;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FF0077;
  color: white;
  margin-bottom: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear;
}

.content-img:hover {
  cursor: pointer;
}

.content-img:hover div {
  visibility: visible;
  opacity: 1;
  vertical-align: middle;
}

.navbar-expand .navbar-nav .nav-link {
  margin-left: 30px;

}

@media only screen and (max-width: 480px) {
  
  
  
  #head {
    background: url(../assets/ProfilePicture.JPG) 500px right;
    height: 500px;
  }


  .page-heading {
    font-size: 30px;
    padding: 0px 20px;
  }

  .navigation {
    font-size: 14px;
  }

  .navbar-expand .navbar-nav .nav-link {
    margin-left: -5px;

  }

  .navbar-nav {
    margin-left: -15px;
  }

  .logo {
    margin-left: 10px;
  }

  .home-subtitle {
    font-size: 30px;
  }

  .about-subtitle {
    font-size: 30px;
  }

  .icons {
    width: 60px;
    height: 55px;
  }

  .content {
    font-size: 16px;
  }

  .content-img div {
    width: 60px;
    height: 55px;
    line-height: 100px;
    font-size: 16px;
    line-height: 55px;
  }
}