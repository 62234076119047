.resume-subtitle {
	text-align: center;
	padding-bottom: 10px;
}

.resume-content {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin: auto;
}

.resume-link {
	color: #FF0077;
}

.see-resumetwo {
font-family: 'Roboto', sans-serif;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  
}

.resume-link:hover {
	color: #FF0077;
	font-weight: bold;
}

.work-experience {
	font-size: 25px;
	color: #FF0077;
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	text-align: center;
	margin-top: 50px;
	margin-bottom: 20px;
}


.see-resume {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  text-align: center;
}

#resume {
	margin: auto;
	padding-bottom: 12rem;
    display: flex;
    flex-direction: column;
    align-content: center;
	margin: auto;
	width: 80%;
	
}

.technical-skills {
	max-width: 1500px;
	padding: 50px;
	padding-left: 100px;
	padding-top: 30px;
}

#resume .resume-timeline {
	max-width: 1500px;
	margin: auto;
	
}
#resume .timeline-wrap {
	position: relative;
	margin-top: 1.5rem;
	margin-bottom: 6rem;
}
#resume .timeline-wrap::before {
	content: "";
	display: block;
	width: 1px;
	height: 100%;
	background: rgba(0, 0, 0, 0.1);
	position: absolute;
	left: 35%;
	top: 0;
}
#resume .timeline-block {
	position: relative;
	padding-top: 1.5rem;
}
#resume .timeline-ico {
	height: 4.8rem;
	width: 4.8rem;
	line-height: 4.8rem;
	background: #FF0077;
	border-radius: 50%;
	text-align: center;
	color: #FFFFFF;
	position: absolute;
	left: 35%;
	top: .9rem;
	margin-left: -2.4rem;
}
#resume .timeline-ico i {
	position: relative;
	left: .05rem;
	top: .2rem;
}

#resume .timeline-header {
	float: left;
	width: 35%;
	padding-right: 90px;
	text-align: right;
}
#resume .timeline-header h3 {
	margin-bottom: 0;
}
#resume .timeline-header p {
	font-family: "poppins-regular", sans-serif;
	font-size: 1.6rem;
	color: #888888;
}
#resume .timeline-content {
	margin-left: 35%;
	padding-left: 60px;
}
#resume .timeline-content h4 {
	position: relative;
	padding-bottom: 1.8rem;
}
#resume .timeline-content h4::after {
	content: "";
	display: block;
	height: 3px;
	width: 50px;
	background: rgba(0, 0, 0, 0.2);
	position: absolute;
	left: 0;
	bottom: 0;
}

@media only screen and (max-width:1024px) {
	#resume .timeline-header {
		padding-right: 50px;
	}
	#resume .timeline-header h3 {
		font-size: 1.8rem;
	}
	#resume .timeline-header p {
		font-size: 1.4rem;
	}
	#resume .timeline-content {
		padding-left: 50px;
	}
}
@media only screen and (max-width:768px) {
	#resume .timeline-wrap::before {
		left: 2.4rem;
	}
	#resume .timeline-ico {
		left: 2.4rem;
	}
	#resume .timeline-header {
		float: none;
		width: auto;
		padding-right: 15px;
		text-align: left;
	}
	#resume .timeline-header h3 {
		font-size: 2rem;
	}
	#resume .timeline-header p {
		font-size: 1.5rem;
		margin-bottom: 1.5rem;
	}
	#resume .timeline-content {
		margin: 0;
	}
	#resume .timeline-content h4 {
		padding-bottom: 0;
		padding-top: 2.1rem;
		margin-bottom: .6rem;
		font-size: 1.7rem;
	}
	#resume .timeline-content h4::after {
		bottom: auto;
		top: 0;
	}
	#resume .timeline-header, #resume .timeline-content {
		padding-left: 7rem;
	}
}
@media only screen and (max-width:480px) {
	

	#resume .timeline-wrap::before {
		left: -1rem;
	}
	#resume .timeline-ico {
		height: 3rem;
		width: 3rem;
		line-height: 3.6rem;
		left: 1.8rem;
		margin-left: -4.3rem;
		font-size: 18px;
		margin-top: 10px;
	}
	#resume .timeline-header, #resume .timeline-content {
		padding-left: 2rem;
		font-size: 16px;
	}

	.resume-content {
		font-size: 16px;
	}

	.timeline-block {
		width: 390px;
	}

	.technical-skills{
		width: 400px;
	}

	.technical-skills li{
		width: 325px;
		margin-right: -10px;
		margin-left: -50px;
	}

}
