.delete {
  font-size: 35px;
  cursor: pointer;
  width: 50px;
  height: 50px;

  justify-self: center;
  display: flex;
  align-items: flex-end;
}

.delete:hover {
  opacity: 0.8;
}

.todo {
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr;
  margin-top: 50px;
}

.task {
  width: auto;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  align-self: bottom;
  margin-top: 10px;
}

ul.nav.nav-tabs {
  font-family: "Poppins", sans-serif;
  font-size: 23px;
}

.portfoliotabs .nav-link {
  color: #ff0077 !important;
}

.counter-btn,
.todo-btn {
  background-color: #ff0077;
  border: 2px solid #ff0077;
  margin: 20px;
  width: 200px;
  height: 50px;
  font-size: 25px;
  color: white;
}

.counter-btn:hover,
.todo-btn:hover,
.file-btn:hover {
  background-color: white;
  color: black;
}

.counter-btn:active,
.todo-btn:active,
.file-btn:active {
  background-color: #ff0077;
  color: white;
}

.counter-title,
.todo-title {
  font-weight: 600;
  margin-top: 80px;
  margin-bottom: 50px;
  font-size: 24px;
  text-align: center;
}

.counter-subtitle {
  margin-top: 20px;
  font-weight: 600;
  text-align: center;
  font-size: 21px;
}

.counter {
  border: 3px solid #ff0077;
  width: 700px;
  margin: auto auto 60px auto;
  padding: 30px;
}

.todo-subtitle {
  margin-bottom: 30px;
  font-weight: 600;
  text-align: center;
}

.todolist {
  background-color: white;
  width: 750px;
  margin: auto auto 70px auto;
  padding: 40px;
  border: 2px solid #ff0077;
}

p.todoinstructions {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}

.done {
  text-decoration: line-through;
}

.todo-input {
  font-size: 22px;
  border: none;
  width: 60%;
  text-align: center;
}

.todo-input:hover {
  background-color: rgba(217, 0, 152, 0.1);
}

.input-div {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.task-number {
  text-align: center;
  width: 100%;
  margin-top: 30px;
}

.checkbox {
  width: 30px;
  height: 30px;
  background-color: rgba(217, 0, 152, 0.4);
  cursor: pointer;
  border: 2px solid white;
  border-radius: 2px;
  position: relative;
  justify-self: left;
  margin-top: 10px;
}

.checkbox:hover {
  opacity: 1;
  border: 2px solid black;
  border-radius: 2px;
}

.file-btn {
  background-color: #ff0077;
  border: 2px solid #ff0077;
  width: 140px;
  height: 50px;
  font-size: 25px;
  color: white;
  margin-top: 30px;
}

.file-div {
  border: 3px solid #ff0077;
  width: 700px;
  border: 3px solid #ff0077;
  width: 600px;
  margin: auto;
  margin-top: 100px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-input {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-input-label {
  display: block;
  position: relative;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  background: linear-gradient(40deg, #ff0077, #d80048);
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  transition: transform 0.2s ease-out;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  letter-spacing: 1.5px;
}

input:hover + label,
input:focus + label {
  transform: scale(1.02);
}

.file-text {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin-top: 30px;
}

.file-info {
  margin-top: 30px;
  font-weight: bold;
}

.folio-tabs {
  margin-top: 50px;
}

.color-btn {
  background-color: #ff0077;
  border: 2px solid #ff0077;
  margin: 20px auto;
  width: 200px;
  height: 50px;
  font-size: 25px;
  color: white;
  border-radius: 2px;
}

.color-btn:hover {
  background-color: white;
  color: black;
}

.color-btn:active {
  background-color: #ff0077;
  color: white;
}

.color-div {
  margin: 50px auto;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  width: 60%;
  border: 2px solid #ff0077;
}

.color-sentence {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  margin: 30px auto;
}

.color-name {
  font-size: 23px;
  font-weight: bold;
}

.radioform-div {
  margin: 50px auto;
  padding-bottom: 30px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  width: 60%;
  border: 2px solid #ff0077;
  border: 2px solid black;
}

.radio {
  float: left;
  margin-right: 10px;
  margin-top: 5px;
  margin-left: 70px;
}

.genderlabel {
  float: left;
  margin-top: 0px;
}

.radio-checkbox {
  float: left;
  margin-right: 10px;
  margin-left: 70px;
  vertical-align: middle;
  position: relative;
  top: 1px;
  width: 20px;
  height: 20px;
  background-color: none;
}

.radio-checkbox:hover {
  background-color: rgb(217, 0, 152);
  cursor: pointer;
}

.descriptionlabel {
  float: left;
  vertical-align: center;
}

.question {
  margin-top: 10px;
  margin-left: 13vw;
}

.radioformtitle {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  font-weight: bolder;
  margin-top: 30px;
  margin-bottom: 20px;
}

.submitbutton {
  width: 260px;
  padding: 10px;
  text-align: center;
  background-color: #ff0077;
  border: 2px solid #ff0077;
  font-size: 25px;
  color: white;
  margin: auto;
  border-radius: 2px;
  display: block;
}

.submitbutton:hover {
  background-color: white;
  color: black;
}

.submitbutton:active {
  background-color: #ff0077;
  color: white;
}

option {
  max-height: 30px;
  overflow: auto;
}

.employee {
  margin: 50px;
}

#answerDiv {
  padding-bottom: 30px;
}

.answersBold {
  font-weight: bold;
}

.displayNone {
  display: none;
}

.show {
  display: block;
}

.table-heading {
  background-color: #990047;
  color: white;
  font-size: 18px;
  letter-spacing: 1.5px;
  font-family: "Roboto", sans-serif;
}

.table-body {
  font-size: 16px;
  letter-spacing: 1.5px;
  font-family: "Roboto", sans-serif;
}

.employee-btn {
  width: 140px;
  padding: 5px;
  text-align: center;
  background-color: #ff0077;
  border: 2px solid #ff0077;
  font-size: 15px;
  color: white;
  margin: auto;
  display: block;
  font-weight: bold;
  border-radius: 2px;
}

.employee-btn:hover {
  background-color: white;
  color: black;
}

.employee-btn:active {
  background-color: #ff0077;
  color: white;
}

.button-row {
  display: flex;
}

.addemployee-btn {
  width: 190px;
  padding: 5px;
  text-align: center;
  background-color: #ff0077;
  border: 2px solid #ff0077;
  font-size: 19px;
  color: white;
  margin: 20px auto;
  display: block;
  border-radius: 2px;
}

.addemployee-btn:hover {
  background-color: white;
  color: black;
}

.addemployee-btn:active {
  background-color: #ff0077;
  color: white;
}

.updateemployee-btn {
  width: 120px;
  padding: 5px;
  text-align: center;
  background-color: #ff0077;
  border: 2px solid #ff0077;
  font-size: 19px;
  color: white;
  margin: 20px auto;
  display: block;
  border-radius: 2px;
}

.updateemployee-btn:hover {
  background-color: white;
  color: black;
}

.updateemployee-btn:active {
  background-color: #ff0077;
  color: white;
}

.employee-input {
  border: 1px solid #ff0077;
  margin: 1px;
  padding: 5px;
  vertical-align: middle;
  width: 90%;
}

#newemployee {
  text-align: left;
}

@media only screen and (max-width: 480px) {
  .counter {
    width: 390px;
  }

  .counter-title,
  .todo-title {
    font-size: 20px;
  }

  .todolist {
    width: 390px;
  }

  .todo-input {
    font-size: 16px;
    height: 60px;
    margin: auto;
  }

  .todo-btn {
    font-size: 16px;
    width: 150px;
    padding: 0px;
  }

  p.todoinstructions {
    font-size: 16px;
  }

  .file-div,
  .color-div,
  .radioform-div {
    width: 400px;
    text-align: center;
  }

  .question {
    margin-left: 10px;
  }

  .question-a {
    margin-left: 0px;
    padding-left: 35px;
    text-align: left;
  }

  .submitbutton {
    font-size: 20px;
    width: 190px;
  }

  ul.nav.nav-tabs,
  .portfoliotabs .nav-link {
    font-size: 16px;
  }

  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr + tr {
    margin-top: 1.5em;
  }
  td {
    border: none;
    position: relative;
    padding-left: 50%;
    text-align: left;
  }
  td:before {
    content: attr(data-label);
    display: inline;
    width: 100%;
    white-space: nowrap;
  }

  .employee-btn {
    font-size: 14px;
  }
}

.projects {
  width: 60%;
  margin: auto;
  text-align: center;
  display: flex;
  gap: 2em;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.project:hover .image {
  opacity: 0.6;
  
}

.project:hover {
  outline: 1px solid #ff0077;
  cursor: pointer;
}

.project {
  position: relative;
  margin-bottom: 50px;
  padding: 1% 0% 3% 0%;
}

.project h1 {
  margin-bottom: 40px;
}

.image {
  opacity: 1;
  display: block;
  transition: .5s ease;
  backface-visibility: hidden;
  width: 90%;
  height: auto;
  margin: auto;
}

.middle {
  transition: .3s ease;
  opacity: 0;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  /* z-index: 99; */
}

.project:hover .middle {
  opacity: 1;
}

.text {
  background-color: #ff0077;
  width: 200px;
  color: white;
  font-size: 16px;
  padding: 0.8em 1.5em;
  border-radius: 30px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  margin: auto;
}

.techStack {
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: white;
  font-size: 16px;
  background-color: #ff0077;
  padding: 0.8em 1.5em;
  width: fit-content;
  border-radius: 30px;
  margin: 30px auto;
}

.spacing {
  margin: auto 15px
}

ul.nav.nav-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
}

.nav-link {
  color: #ff0077 !important;

}