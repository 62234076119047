.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
   
  .box {
    position: relative;
    width: 80%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: white;
    border-radius: 2px;
    padding: 0.5px 10px 60px 10px;
    overflow: none;
  }
   
  .close-icon {
    content: 'x';
    color: white;
    font-weight: bold;
    cursor: pointer;
    position: fixed;
    right: calc(15% - 47px);
    top: calc(100vh - 85vh - 18px);
    background: #FF0077;
    width: 25px;
    height: 25px;
    border-radius: 10%;
    line-height: 20px;
    text-align: center;
    font-size: 20px;
  }

  @media only screen and (max-width: 480px) {
    .close-icon {
      right: calc(15% - 22px);
    }
  }